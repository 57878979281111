<template lang="pug">
    .main-wrapper.agendamento-revenda-listar
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-12.ta-right
                    h1.text-header.text-secondary MedClub / <b>Agendamentos</b>

        TabView(@tab-change='onTabChange')
            TabPanel(header='Consultas' v-bind:ieTipoAgenda="'C'" :active="filters.ie_tipo_agenda == 'C'" :disabled='waiting')
            TabPanel(header='Exames'  v-bind:ieTipoAgenda="'E'" :active="filters.ie_tipo_agenda == 'E'" :disabled='waiting')
            TabPanel(header='Procedimentos'  v-bind:ieTipoAgenda="'P'" :active="filters.ie_tipo_agenda == 'P'" :disabled='waiting')

        Panel.mb-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576')
            .p-grid.p-fluid.p-align-end

                .p-col-12.p-md-3
                    label.form-label Código da guia:
                    .p-inputgroup
                        InputText( @keyup.enter.native='applyFilters()' v-model='filters.cd_transacao_financeira'
                            @keyup='checkEmptyField("cd_transacao_financeira")' )
                        Button(icon='jam jam-search' @click='applyFilters()')

                .p-col-12.p-md-4(v-if="filters.ie_tipo_agenda === 'C'")
                    label.form-label Especialista:
                    Dropdown( v-model='filters.especialista' :options='options.especialistas' dataKey='value' :filter='options.especialistas.length > 0'
                        optionLabel='text' optionValue='value' placeholder='TODOS' @change='applyFilters()' )

                .p-col-12.p-md-4(v-if="filters.ie_tipo_agenda === 'C'")
                    label.form-label Especialidade:
                    Dropdown( v-model='filters.especialidade' :options='options.especialidades' :filter='options.especialidades.length > 0'
                        dataKey='value' optionLabel='text' optionValue='value' placeholder='TODAS' @change='applyFilters()' )

                .p-col-6.p-md-3
                    label.form-label Data inicial:
                    .p-inputgroup
                        Calendar( v-model='filters.dt_inicio' dateFormat="dd/mm/yy" :locale="ptbr" :maxDate='filters.dt_fim'
                            :manualInput='false' :touchUI='windowInnerWidth < 576' @input='applyFilters()' )
                        Button.p-button-danger(icon='jam jam-rubber' v-tooltip.top="'Limpar'" @click='filters.dt_inicio = null; applyFilters()')

                .p-col-6.p-md-3
                    label.form-label Data final:
                    .p-inputgroup
                        Calendar( v-model='filters.dt_fim' dateFormat="dd/mm/yy" :locale="ptbr" :minDate='filters.dt_inicio'
                            :manualInput='false' :touchUI='windowInnerWidth < 576' @input='applyFilters()' )
                        Button.p-button-danger(icon='jam jam-rubber' v-tooltip.top="'Limpar'" @click='filters.dt_fim = null; applyFilters()')

                .p-col-12.p-md-4
                    label.form-label Nome do paciente:
                    .p-inputgroup
                        InputText( @keyup.enter.native='applyFilters()' v-model='filters.nm_paciente'
                            @keyup='checkEmptyField("nm_paciente")' )
                        Button(icon='jam jam-search' @click='applyFilters()')

                .p-col-12.p-md-3
                    label.form-label Situação:
                    MultiSelect.input-ieStatus( v-model='filters.ie_status' :options='options.ie_status'
                        dataKey='value' optionLabel='text' optionValue='value' placeholder='Selecione' @change='applyFilters()' )

                .p-col-12.p-md-2
                    Button(label='Limpar filtros' icon='jam jam-rubber' @click='clearFilters()')

        .ta-right.my-4
            Button.ml-2.btn-calendario(label='Ver calendário' icon="jam jam-calendar" @click="$router.push('/revendedor/ver-calendario')")
            Button.ml-2.btn-calendario(label='Ver preços' icon="jam jam-coin" @click="$router.push('/revendedor/ver-precos/')")
            Button.ml-2.btn-criarAgendamento(label='Agendar' icon="jam jam-plus" @click="$router.push('/revendedor/agendar/0/')")

        ProgressBar(v-if='waiting' mode="indeterminate")

        div(v-else-if='!list.length')
            p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.

        div(v-else)
            DataView.my-2.dataview(:value="list" layout="grid")
                template(#grid="props")
                    .p-col-12.p-md-3(style="padding: .5em")
                        Panel.panel-list.ta-center(:header="props.data.nm_paciente.split(' ')[0]")
                            .ta-left
                                p <b>Horário:</b> {{ props.data.hr_agenda_f }}
                                p <b>Nome do paciente:</b> {{ props.data.nm_paciente }}
                                p(v-if="filters.ie_tipo_agenda === 'C'") <b>Especialidade:</b> {{ props.data.ds_especialidade }}
                                p(v-if="filters.ie_tipo_agenda === 'C'") <b>Especialista:</b> {{ props.data.ds_especialista }}
                                p <b>Situação: </b>
                                    span.mtd-badge(:class="{ co3: props.data.ie_status === 'A', co5: props.data.ie_status === 'EX' }") {{ props.data.ie_status_f }}
                                p(v-if='props.data.cdGuiaEletronica') <b>Código da guia:</b> {{ props.data.cdGuiaEletronica }}
                                p(v-if="filters.ie_tipo_agenda === 'E'") <b>Procedimento:</b> {{ props.data.nm_procedimento }}
                            .ta-right.mt-3
                                Button.p-button-raised.p-button-rounded(icon="jam jam-printer" @click='imprimirGuia(props.data.id)')

            Paginator.dataview(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

            Panel.datatable(header='Lista de agendamentos')
                DataTable(:value="list")
                    Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' header='Horário')
                        template(#body='props')
                            p
                                span {{props.data.dt_agenda}} <br/>
                                em {{props.data.hr_agenda_f}}
                    Column(headerStyle='width: 15%;' header='Nome do paciente')
                        template(#body='props')
                            p
                                span {{props.data.nm_paciente}}<br />
                                em(:style={'font-size':'10px'}) Agendado em: {{props.data.dt_agendamento_f}}
                    Column(v-if="filters.ie_tipo_agenda === 'C'" headerStyle='width: 14%;' field='ds_especialidade' header='Especialidade')
                    Column(v-if="filters.ie_tipo_agenda === 'C'" headerStyle='width: 14%;' field='ds_especialista' header='Especialista')
                    Column(v-if="filters.ie_tipo_agenda === 'E'" headerStyle='width: 28%;' header='Procedimento(s)')
                        template(#body='props')
                            p(v-if='props.data.nm_procedimento !== "" && props.data.nm_procedimento !== "-"') {{props.data.nm_procedimento}}
                            p(v-else v-for='i in props.data.cd_procedimentos' :key='i.id')
                                span &bull; {{ i.nm_procedimento }}
                    Column(headerStyle='width: 9%;' bodyStyle='padding: 0;' header='Situação')
                        template(#body='props')
                            .cell(:class="{ ex: props.data.ie_status === 'EX', a: props.data.ie_status === 'A', l: props.data.ie_status === 'L' }")
                                span {{ props.data.ie_status_f }} <br />
                                span(v-if="['EX','A'].includes(props.data.ie_status)" style='font-size:10px') ({{props.data.ie_unidade_f}})
                    Column(headerStyle='width: 8%;' header='Tipo' bodyStyle='text-align:center')
                        template(#body='props')
                            span {{ props.data.ie_retorno ? 'Retorno' : 'Normal' }}
                    Column(headerStyle='width: 15%;' field='nr_telefone_paciente_f' header='Telefone')
                        template(#body='props')
                            p.ta-center(style='margin:0')
                                span(style='display:inline-block;vertical-align:middle') {{ props.data.nr_telefone_paciente_f }}
                                a.whatsapp-link(v-if='props.data.nr_telefone_paciente' :href="`https://api.whatsapp.com/send?phone=${ props.data.nr_telefone_paciente_ws }`" target='_blank')
                                    i(class='jam jam-whatsapp')

                    Column(headerStyle='width: 10%; text-align: center' header="Ações")
                        template(#body='props')
                            .ta-center
                                ProgressSpinner.small-spinner(
                                    v-if="waitingImprimirGuiaIds.includes(props.data.id)"
                                    strokeWidth="6"
                                )
                                Button.p-button-raised.p-button-rounded.mr-1(
                                    v-else-if="props.data.ie_status === 'A'"
                                    v-tooltip.top="'Imprimir Guia'"
                                    icon="jam jam-printer"
                                    @click='imprimirGuia(props.data.id)'
                                )
                Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

</template>

<style lang="scss">
    .agendamento-revenda-listar {
        .dataview {
            @media all and (min-width: 577px) {
                display: none;
            }
        }
        .datatable {
            @media all and (max-width: 576px) {
                display: none;
            }
            .cell {
                padding: 16px 0;
                text-align: center;
                &.ex  {  background-color: #e4f8e1; }
                &.a  { background-color: #faf3dd; }
                &.l  { background-color: #e1f0f8; }
                &.r  { background-color: #f1e1f8; }
            }
        }
        .filter-data {
            .p-inputtext {
                text-align: center;
            }
        }
        .p-tabview-panels {
            padding: 0 !important;
            border: none !important;
        }
        .p-tabview {
            padding-bottom: 0;
        }
        .p-dropdown-filter-container {
            width: 100%;
        }
        .btn-criarAgendamento, .btn-calendario {
            font-size: 16px; font-weight: 700
        }
        .whatsapp-link {
            margin-left: 8px;
            background-color: #0da64d;
            color: #fff;
            font-weight: 700;
            border-radius: 50%;
            padding: 4px;
            display: inline-block;
            vertical-align: middle;
            width: 28px;
            height: 28px;
            line-height: 24px;
            text-align: center;
            transition: 0.25s;
            &:hover {
                background-color: #0a7d3a;
            }
        }
        .input-ieStatus .p-multiselect-header {
            display: none;
        }
        .small-spinner {
            width: 29px;
            height: auto;
        }
    }
</style>

<script>
    import DataView from 'primevue/dataview'
    import Column from 'primevue/column'
    import DataTable from 'primevue/datatable'
    import Paginator from 'primevue/paginator'
    import Panel from 'primevue/panel'
    import Calendar from 'primevue/calendar'
    import ProgressBar from 'primevue/progressbar'
    import ProgressSpinner from 'primevue/progressspinner'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import InputText from 'primevue/inputtext'
    import TabView from 'primevue/tabview'
    import TabPanel from 'primevue/tabpanel'
    import Tooltip from 'primevue/tooltip'
    import MultiSelect from 'primevue/multiselect'

    import moment from 'moment'
    import { Agendamento, Agenda, Especialista, Empresa } from './../../middleware'
    import { pCalendarLocale_ptbr, fixTelefoneFormat } from './../../utils'
	import wsConfigs from './../../middleware/configs'

    export default {
        components: { DataView, Panel, ProgressBar, Calendar, TabView, Tooltip, Paginator,
            Button, Dropdown, InputText, DataTable, Column, TabPanel, MultiSelect, ProgressSpinner },
        directives: { tooltip: Tooltip },
        created () {
            if (['C', 'E'].includes(this.$route.query.tb)) this.filters.ie_tipo_agenda = this.$route.query.tb
            if (!isNaN(this.$route.query.pg)) this.paginator.page = this.$route.query.pg

            this.getEspecialidades()
            this.getEspecialistas()
            this.applyFilters()
        },
        data () {
            return {
                list: [],
                ptbr: pCalendarLocale_ptbr,
                windowInnerWidth: window.innerWidth,
                waiting: false,
                waitingImprimirGuiaIds: [],
                filters: {
                    dt_inicio: moment()._d,
                    dt_fim: moment()._d,
                    ie_status: ['A', 'EX'],
                    cd_transacao_financeira: null,
                    especialidade: null,
                    especialista: null,
                    ie_tipo_agenda: 'C',
                    nm_paciente: null,
                },
                options: {
                    especialistas: [],
                    especialidades: [],
                    procedimentos: [],
                    ie_status: [
                        { value: 'A', text: 'Agendado' },
                        { value: 'EX', text: 'Executado' }
                    ],
                },
                paginator: {
                    page: this.$route.query.pg ? this.$route.query.pg : 1,
                    per_page: wsConfigs.paginator_perPage,
                    count: 0
                },
                order: {
                    field: 'dt_agenda',
                    sortOrder: 1
                }
            }
        },
        methods: {
            applyFilters (page) {
                this.paginator.page = page || 1
                let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }

                params.order = `${ this.order.sortOrder == -1 ? '-' : '' }${ this.order.field }`

                if (this.$route.query.tb != this.filters.ie_tipo_agenda || this.$route.query.pg != this.paginator.page)
                    this.$router.replace( { query: { tb: this.filters.ie_tipo_agenda, pg: this.paginator.page } } )

                Object.keys(this.filters).forEach((key) => {
                    if (this.filters[key]) {
                        if (key.substr(0, 3) == 'dt_')
                            params[key] = moment(this.filters[key]).format('YYYY-MM-DD')
                        else if (key === 'ie_status') {
                            params[key] = this.filters[key].toString()
                        } else params[key] = this.filters[key]
                    }
                })

                this.getList(params)
            },
            onPage (ev) {
                this.applyFilters(ev.page + 1)
            },
            checkEmptyField (field) {
                if (! this.filters[field].length) this.applyFilters()
            },
            clearFilters () {
                Object.keys(this.filters).forEach(k => {
                    if (k != 'ie_tipo_agenda') this.filters[k] = null
                    else if (k == 'dt_inicio' || k == 'dt_fim') this.filters[k] = null
                })
                this.applyFilters()
            },
            onTabChange (ev) {
                this.filters.ie_tipo_agenda = ev.tab.$attrs.ieTipoAgenda
                this.filters.especialista = null
                this.filters.especialidade = null
                this.paginator.page = 1
                this.applyFilters()
            },
            getList (params) {
                this.waiting = true
                this.list = []
                Empresa.getAgenda(params).then(response => {
                    if (response.status === 200) {
                        let ie_status_obj = {}
                        this.options.ie_status.forEach(e => { if (e.value) ie_status_obj[e.value] = e.text })
                        this.paginator.count = response.data.count

                        response.data.results.forEach(entry => {
                            entry.dt_agenda = moment(entry.dt_agenda).format('DD/MM/YYYY')
                            let aux = entry.hr_agenda.split(':')
                            entry.hr_agenda_f = `${ aux[0] }:${ aux[1] }`
                            entry.ie_status_f = ie_status_obj[entry.ie_status]
                            entry.ie_unidade_f = entry.ie_unidade ? 'Unidade' : 'App'
                            entry.dt_agendamento_f = moment(entry.dt_agendamento).format('DD/MM/YYYY HH:mm')
                            if (entry.nr_telefone_paciente) {
                                entry.nr_telefone_paciente_f = fixTelefoneFormat(entry.nr_telefone_paciente)
                                entry.nr_telefone_paciente_ws = entry.nr_telefone_paciente.match(/\d+/g).join('')
                                if (entry.nr_telefone_paciente_ws.substr(0, 2) === '55') {
                                    entry.nr_telefone_paciente_ws = `${ entry.nr_telefone_paciente_ws }`
                                } else {
                                    entry.nr_telefone_paciente_ws = `55${ entry.nr_telefone_paciente_ws }`
                                }
                            }
                            entry.cdGuiaEletronica = entry.guiaeletronica_set[0] ? entry.guiaeletronica_set[0] : ''
                        })
                        this.list = response.data.results
                    }
                    this.waiting = false
                })
            },
            imprimirGuia (id) {
                this.waitingImprimirGuiaIds.push(id);
                Agendamento.imprimirGuia(id).then(response => {
                    this.waitingImprimirGuiaIds = this.waitingImprimirGuiaIds.filter(x => x !== id);
                    if (response.status === 200) {
                        if (response.data.detail) {
                            this.$toast.error(response.data.detail, { duration: 3000 });
                        } else {
                            const blob = new Blob([response.data], { type: 'application/pdf; charset=utf-8' });
                            const fileURL = URL.createObjectURL(blob);
                            window.open(fileURL);
                        }
                    }
                });
            },
            getEspecialistas () {
                this.options.especialistas = []
                Agenda.getEspecialistasAgendados().then(response => {
                    if (response.status == 200) {
                        let mem = {}
                        response.data.forEach(e => {
                            if (! mem[e.cd_especialista]) {
                                mem[e.cd_especialista] = true
                                this.options.especialistas.push({ text: e.nm_especialista, value: e.cd_especialista })
                            }
                        })
                        this.options.especialistas.sort((a, b) => a.text > b.text ? 1 : -1)
                        this.options.especialistas.unshift({ text: 'TODOS', value: null })
                    }
                })
            },
            getEspecialidades() {
                this.options.especialidades = []
                this.options.especialidades.push({ text: 'TODAS', value: null })
                if (this.filters.especialista == null)
                    Agendamento.getEspecialidades().then(response => {
                        if (response.status == 200) response.data.forEach(e => this.options.especialidades.push({ text: e.nm_especialidade, value: e.id }))
                    })
                else Especialista.getEspecialidades(this.filters.especialista).then(response => {
                    if (response.status == 200) response.data.forEach(e => this.options.especialidades.push({ text: e.nm_especialidade, value: e.id }))
                })
            },
        }
    }
</script>
